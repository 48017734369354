@use "sass:list";

@mixin gen-carousel-text-opacities($opacities) {
  @for $number from 1 through list.length($opacities) {
    #carousel-item-#{$number} > .carousel-caption {
      background-color: rgba(0, 0, 0, list.nth($opacities, $number));
    }
  }
}

@mixin gen-carousel-image-center($centers...) {
  @for $number from 1 through list.length($centers) {
    #carousel-item-#{$number} > img {
      object-position: list.nth(list.nth($centers, $number), 1)
        list.nth(list.nth($centers, $number), 2);
    }
  }
}

@mixin gen-sports-page-image-center($centers...) {
  @for $number from 1 through list.length($centers) {
    #picture-#{$number} > picture > img {
      object-position: list.nth(list.nth($centers, $number), 1)
        list.nth(list.nth($centers, $number), 2);
    }
  }
}
